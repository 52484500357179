export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "Contact information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연락처 정보"])},
      "Email address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일"])},
      "State": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["도"])},
      "First name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름"])},
      "Zip code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["우편번호"])},
      "Last name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["성"])},
      "City": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시"])},
      "Nickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["닉네임"])},
      "Phone number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전화번호"])},
      "Age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나이"])},
      "Organization name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["단체명"])},
      "Gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["성별"])},
      "Organization type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["단체 유형"])},
      "People collecting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수거 전화번호"])},
      "Edit Your Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["프로필 수정"])},
      "Connect your account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정 연동하기"])},
      "Connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connected"])},
      "Connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect"])},
      "Street Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주소"])},
      "Extended Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상세 주소"])}
    }
  })
}
