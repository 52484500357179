export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "banner_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["캠페인"])},
      "banner_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["테라사이클에의 다양한 재활용 캠페인으로, 재활용이 어렵다고 생각되는 품목까지도 재활용 할 수 있도록 도와드리겠습니다."])}
    }
  })
}
